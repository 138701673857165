import React from 'react';
import FooterLogo from '../../../assets/img/FooterLogo.png';
import { MdDoubleArrow,MdOutlineArrowForwardIos } from "react-icons/md";


 const Footer1=()=>{
    return(<>
        <section className={'footer1'}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4 widget1 ">
                        <img src={FooterLogo}/>
                        <p>We simplify the complicated. Message us today to set up a meeting.</p>
                    </div>
                    <div className="col-12 col-md-3 offset-md-1 widget2">
                        <h6>Quick Links</h6>
                        <ul>
                            <li><a href="">Home</a></li>
                            <li><a href="">Contact Us</a></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-4 widget3">
                        <h6>Newsletter</h6>
                        <p>Subscribe For News Updates</p>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="Enter your email address"
                                   aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                <span className="input-group-text" id="basic-addon2">
                                    <MdOutlineArrowForwardIos/>
                                    <MdOutlineArrowForwardIos/>
                                </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);

}
export default Footer1;