import React from 'react';
import Footer1 from "../../compnent/footer/footer1/footer1";
import AbsoluteFooter from "../../compnent/footer/absoluteFooter/absoluteFooter";
import Header from "../../compnent/header/header"
import Contact from "../../assets/img/contactUs.png"

import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import AbsoluteFooterContact from "../../compnent/footer/absoluteFooter/absoluteFooterContact";
  

const ContactUs=()=>{
    return(
        <>
 <ToastContainer />
<>
                <Header/>
                <section className={'contactUs'}>
                    <div className="container">
                        <div className="row w-100 m-0">
                            <div className="col-12 col-md-6 contactUsHeading">
                                <div>
                                    <h1><span> We’d love</span>   to hear <br/>
                                        from you </h1></div>
                                <img src={Contact} alt={'contactus'}/>
                            </div>
                            <div className="col-12 col-md-6 form heading  ps-md-5">
                                <div className={'contacthr'}>
                                    <h1><span> Contact </span>Us </h1>
                                </div>
                                <div className="row w-100 pb-0 ">
                                    <div className="col-md-6">
                                        <label aria-required htmlFor="inputEmail4" className="form-label">First Name</label>
                                        <input type="text" className="form-control" id="inputEmail4"/>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="inputPassword4" className="form-label">Last Name</label>
                                        <input type="text" className="form-control" id="inputPassword4"/>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="inputEmail4" className="form-label">Email</label>
                                        <input type="email" className="form-control" id="inputEmail4"/>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="inputPassword4" className="form-label">Discord ID</label>
                                        <input type="text" className="form-control" id="inputPassword4"/>
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="exampleFormControlTextarea1" className="form-label">
                                            Message</label>
                                        <textarea className="form-control" id="exampleFormControlTextarea1"
                                                  rows="4"></textarea>

                                    </div>




                                    <div className="col-12">
                                        <button onClick={()=>{
                                            toast("Thanks for conatcting us. We will reach you soon !");
                                        }}  className="btn btn-primary shadow-none border-0">Submit</button>
                                    </div>
                                    <div className="col-12 nameInfocontact">
                                      <div className="row">
                                            <div className="col-12 col-md-6 d-flex">
                                            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="42" height="42" rx="8" fill="url(#paint0_linear_67_6577)"/>
                                                <path d="M31.6881 11.9792C29.7505 11.0727 27.6788 10.4138 25.5128 10.0387C25.2468 10.5196 24.9361 11.1665 24.7218 11.6811C22.4193 11.3348 20.138 11.3348 17.8779 11.6811C17.6637 11.1665 17.3458 10.5196 17.0775 10.0387C14.9092 10.4138 12.835 11.0751 10.8975 11.984C6.98934 17.8897 5.92991 23.6487 6.45963 29.3259C9.05169 31.2616 11.5637 32.4375 14.0333 33.2069C14.6431 32.3677 15.1869 31.4756 15.6554 30.5354C14.7632 30.1964 13.9086 29.778 13.1011 29.2922C13.3153 29.1335 13.5248 28.9676 13.7273 28.7969C18.6524 31.1005 24.0037 31.1005 28.87 28.7969C29.0749 28.9676 29.2844 29.1335 29.4963 29.2922C28.6864 29.7804 27.8295 30.1988 26.9372 30.5378C27.4057 31.4756 27.9472 32.3701 28.5593 33.2093C31.0313 32.4398 33.5456 31.264 36.1377 29.3259C36.7593 22.7446 35.0759 17.0385 31.6881 11.9792ZM16.3264 25.8345C14.8479 25.8345 13.6355 24.4542 13.6355 22.7734C13.6355 21.0926 14.8221 19.71 16.3264 19.71C17.8308 19.71 19.0433 21.0902 19.0174 22.7734C19.0197 24.4542 17.8308 25.8345 16.3264 25.8345ZM26.2709 25.8345C24.7924 25.8345 23.58 24.4542 23.58 22.7734C23.58 21.0926 24.7665 19.71 26.2709 19.71C27.7753 19.71 28.9878 21.0902 28.9619 22.7734C28.9619 24.4542 27.7753 25.8345 26.2709 25.8345Z" fill="white"/>
                                                <defs>
                                                <linearGradient id="paint0_linear_67_6577" x1="1.4" y1="42" x2="44.8545" y2="38.8033" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#6D77B0"/>
                                                <stop offset="1" stop-color="#9DA6CE"/>
                                                </linearGradient>
                                                </defs>
                                            </svg>

                                            <div>
                                                <h6>Add us on Discord</h6>
                                                <p>@Flook#3470</p>

                                            </div>

                                        </div>
                                        <div className="col-12 col-md-6 d-flex">
                                            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="42" height="42" rx="8" fill="url(#paint0_linear_67_6585)"/>
                                                <path d="M34 14.3298V29.3991C34 30.0875 33.7649 30.7482 33.3464 31.2355C32.9279 31.7231 32.3599 31.9979 31.7668 32H10.2332C9.64018 31.9979 9.07215 31.7231 8.6536 31.2355C8.2351 30.7481 8 30.0875 8 29.3991V14.3298L20.6075 24.3733C20.8438 24.5637 21.1571 24.5637 21.3934 24.3733L34 14.3298Z" fill="white"/>
                                                <path d="M33.9864 12.4009L21.0001 22.7406L8.01367 12.4009C8.05398 11.7489 8.30508 11.139 8.71682 10.6935C9.12856 10.2483 9.67042 10.0004 10.2333 10H31.767C32.3298 10.0004 32.8716 10.2483 33.2834 10.6935C33.6951 11.139 33.9462 11.7488 33.9866 12.4009H33.9864Z" fill="white"/>
                                                <defs>
                                                <linearGradient id="paint0_linear_67_6585" x1="1.4" y1="42" x2="44.8545" y2="38.8033" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#6D77B0"/>
                                                <stop offset="1" stop-color="#9DA6CE"/>
                                                </linearGradient>
                                                </defs>
                                            </svg>


                                            <div>
                                                <h6>Email Us</h6>
                                                <p>adam@merlynlabs.io</p>

                                            </div>

                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <AbsoluteFooter/>
            </>



        </>);
}
export default ContactUs;