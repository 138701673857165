import React from 'react';
import Logo from '../../assets/img/Logo.png';
import { useNavigate ,Link,useLocation} from "react-router-dom";


 const Header=()=>{
    const location = useLocation();

    let navigate = useNavigate();

    return(
        <>
            <header >
                <div className={'container '}>
                <nav className="navbar navbar-expand-md navbar-light ">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#">
                            <svg width="169" height="44" viewBox="0 0 169 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 36.9136C0 36.9136 6.42539 34.5369 10.7935 33.6889C10.7935 33.6889 13.5892 34.4969 15.4849 34.4314C15.4849 34.4314 13.0124 33.445 11.8883 32.364C20.0477 25.9036 13.6223 11.4505 30.9073 4.8299C26.6678 16.9245 29.7501 27.1738 35.2754 31.8217C35.2754 31.8217 32.127 34.1547 27.2005 35.0465C27.2005 35.0465 32.5972 35.4723 36.6677 33.4778C36.6677 33.4778 43.7801 34.2858 47.5494 36.9136C37.4392 40.0546 23.5598 41.0301 17.7332 40.2257L22.274 29.5724L24.5003 31.5669L25.2717 27.7489L28.6994 26.2639L25.4848 24.3131L25.1432 21.2157L22.7442 23.294L19.1476 22.4459L20.4334 25.798L18.119 28.5569L20.9037 28.9391L13.6664 44L12.5532 39.7125C12.5495 39.7162 5.18367 39.0792 0 36.9136Z" fill="white"/>
                                <path d="M36.2085 14.9518L36.3995 17.1465L38.2327 18.3876L36.2085 19.0901L35.8228 21.4122L33.9602 19.4723L31.3555 19.3449L32.9646 17.7834L32.2555 15.778L34.4084 16.3203L36.2085 14.9518Z" fill="white"/>
                                <path d="M36.4289 6.36945L37.8286 7.53414L39.6214 7.19929L38.8573 8.77163L39.9925 10.3403L37.7735 10.2857L36.1865 11.7088L36.2085 9.87809L34.6215 9.12104L36.1865 8.19656L36.4289 6.36945Z" fill="white"/>
                                <path d="M36.1057 0.396724L35.7273 1.66697L36.473 2.75887L35.1872 2.71156L34.4562 3.92357L33.8463 2.43494L32.4209 1.798L33.6626 1.27389L33.7104 0L34.7941 0.771611L36.1057 0.396724Z" fill="white"/>
                                <path d="M57.5603 10.6023V23.4577H56.0688V8.30206H58.1371L63.8057 21.5141L69.5147 8.30206H71.561V23.4577H70.0915V10.6023L64.5111 23.4577H63.104L57.5603 10.6023Z" fill="white"/>
                                <path d="M74.0702 17.8016C74.0702 14.5514 76.2451 12.1237 79.2245 12.1237C82.4206 12.1237 84.5073 14.6788 84.2538 18.2456H75.5617C75.6903 20.8844 77.0974 22.5077 79.2906 22.5077C81.1642 22.5077 82.4647 21.474 82.8284 19.7015H84.2979C83.7873 22.1729 81.8916 23.6688 79.3126 23.6688C76.1569 23.6688 74.0702 21.3248 74.0702 17.8016ZM75.5617 17.2302H82.6998C82.5713 14.7406 81.2708 13.2629 79.2061 13.2629C77.1819 13.2629 75.7528 14.8025 75.5617 17.2302Z" fill="white"/>
                                <path d="M88.2179 23.4576H86.7263V12.3348H88.1113V14.868C88.8791 13.2229 90.5397 12.1674 92.3949 12.1674V13.7069C90.029 13.6232 88.2179 15.0791 88.2179 17.5068V23.4576Z" fill="white"/>
                                <path d="M94.2244 23.4577V8.30206H95.716V23.4577H94.2244Z" fill="white"/>
                                <path d="M101.851 27.5304H100.253L101.895 23.3084L97.4426 12.3312H99.0407L102.663 21.5359L106.094 12.3312H107.608L101.851 27.5304Z" fill="white"/>
                                <path d="M110.822 23.4577H109.331V12.3348H110.716V14.424C111.44 13.0518 112.803 12.1237 114.423 12.1237C116.767 12.1237 118.28 13.6014 118.28 16.2402V23.4577H116.789V16.7461C116.789 14.6133 115.808 13.4122 113.974 13.4122C112.226 13.4122 110.822 14.9117 110.822 16.979V23.4577Z" fill="white"/>
                                <path d="M122.549 23.4577V8.30206H125.786V20.5641H132.431V23.4577H122.549Z" fill="white"/>
                                <path d="M141.168 22.0637C140.422 23.1192 139.294 23.6688 137.736 23.6688C135.37 23.6688 133.794 22.2966 133.794 20.1856C133.794 17.9909 135.584 16.808 138.908 16.808C139.548 16.808 140.102 16.8516 140.804 16.9354V16.2584C140.804 14.9918 140.08 14.2529 138.842 14.2529C137.564 14.2529 136.796 14.9918 136.689 16.2584H134.026C134.195 13.6633 136.094 11.9745 138.842 11.9745C141.825 11.9745 143.574 13.6014 143.574 16.3639V23.4577H141.168V22.0637ZM138.379 21.6415C139.893 21.6415 140.808 20.8189 140.808 19.5086V18.7079C140.106 18.6023 139.614 18.5587 139.103 18.5587C137.42 18.5587 136.59 19.0864 136.59 20.0982C136.586 21.0518 137.27 21.6415 138.379 21.6415Z" fill="white"/>
                                <path d="M152.439 23.6688C150.969 23.6688 149.731 23.0136 148.861 21.8307V23.4577H146.135V8.30206H149.096V13.6415C149.926 12.5423 151.076 11.9745 152.442 11.9745C155.51 11.9745 157.49 14.4859 157.49 17.8198C157.49 21.1137 155.506 23.6688 152.439 23.6688ZM151.777 14.5696C150.348 14.5696 149.114 15.6469 149.114 17.7761C149.114 19.9053 150.308 21.0482 151.777 21.0482C153.225 21.0482 154.441 19.9308 154.441 17.8198C154.441 15.6688 153.229 14.5696 151.777 14.5696Z" fill="white"/>
                                <path d="M163.945 23.6687C160.705 23.6687 158.897 22.1292 158.662 19.6178H161.495C161.517 20.7788 162.516 21.5177 163.989 21.5177C165.139 21.5177 166.013 21.1574 166.013 20.3566C166.013 19.6396 165.543 19.3448 164.459 19.1337L162.413 18.7552C160.367 18.3949 159.132 17.2775 159.132 15.5268C159.132 13.4376 160.944 11.9818 163.776 11.9818C166.63 11.9818 168.592 13.4813 168.805 15.909H165.973C165.866 14.8098 165.036 14.1364 163.78 14.1364C162.692 14.1364 161.968 14.5368 161.968 15.2538C161.968 15.9308 162.439 16.2038 163.46 16.393L165.653 16.7934C167.869 17.2156 169 18.2274 169 19.8544C168.993 22.2129 167.182 23.6687 163.945 23.6687Z" fill="white"/>
                                <path d="M59.0592 31.4103L57.8983 35.596H57.362L56.0651 30.7151H56.5648L57.6265 34.8535L58.78 30.7151H59.3274L60.4663 34.868L61.5353 30.7151H62.0423L60.7455 35.596H60.2018L59.0592 31.4103Z" fill="white"/>
                                <path d="M66.4913 35.5996V30.7188H67.0056V35.5996H66.4913Z" fill="white"/>
                                <path d="M71.528 35.5996V35.232L74.3898 31.1629H71.6198V30.7188H75.0364V31.0937L72.1672 35.1592H75.1393V35.5996H71.528Z" fill="white"/>
                                <path d="M82.6117 34.2457H80.3119L79.7902 35.5996H79.2833L81.1973 30.7188H81.7594L83.6661 35.5996H83.1223L82.6117 34.2457ZM80.4699 33.8307H82.4464L81.4655 31.2611L80.4699 33.8307Z" fill="white"/>
                                <path d="M88.115 35.5996V30.7188H89.871C90.9144 30.7188 91.5646 31.2575 91.5646 32.1929C91.5646 32.9281 91.076 33.4777 90.3009 33.6051L91.6932 35.596H91.076L89.7535 33.6669H88.6293V35.596H88.115V35.5996ZM88.6293 33.2411H89.8306C90.5838 33.2411 91.0503 32.8735 91.0503 32.1929C91.0503 31.5123 90.5838 31.1447 89.8306 31.1447H88.6293V33.2411Z" fill="white"/>
                                <path d="M96.1935 35.5996V30.7188H97.5932C99.173 30.7188 100.044 31.7124 100.044 33.1392C100.044 34.5477 99.1399 35.5996 97.5932 35.5996H96.1935ZM96.7079 35.1556H97.5785C98.8276 35.1556 99.5183 34.3476 99.5183 33.1356C99.5183 31.9308 98.8386 31.1592 97.5785 31.1592H96.7079V35.1556Z" fill="white"/>
                                <path d="M106.245 35.6651C105.099 35.6651 104.426 35.0318 104.379 34.1073H104.9C104.948 34.7989 105.389 35.2502 106.238 35.2502C106.936 35.2502 107.487 34.8971 107.487 34.3111C107.487 33.8089 107.2 33.5759 106.498 33.434L105.874 33.3102C105.224 33.1792 104.537 32.8808 104.537 32.0327C104.537 31.2502 105.231 30.646 106.212 30.646C107.171 30.646 107.913 31.2429 107.968 32.1747H107.439C107.406 31.5304 106.925 31.0609 106.219 31.0609C105.466 31.0609 105.058 31.5159 105.058 32.0072C105.058 32.5859 105.551 32.7606 106.102 32.8698L106.726 32.9972C107.556 33.1683 108.03 33.5068 108.03 34.242C108.027 35.0209 107.391 35.6651 106.245 35.6651Z" fill="white"/>
                                <path d="M117.553 33.1501C117.553 31.7306 118.611 30.6169 120.036 30.6169C121.462 30.6169 122.527 31.7306 122.527 33.1501C122.527 34.5696 121.465 35.6979 120.036 35.6979C118.607 35.6979 117.553 34.5732 117.553 33.1501ZM121.991 33.1501C121.991 31.9818 121.201 31.0718 120.036 31.0718C118.875 31.0718 118.082 31.9818 118.082 33.1501C118.082 34.3184 118.879 35.2429 120.036 35.2429C121.204 35.2465 121.991 34.3221 121.991 33.1501Z" fill="white"/>
                                <path d="M127.057 35.5996V30.7188H129.882V31.1592H127.571V33.0628H129.562V33.5032H127.571V35.596H127.057V35.5996Z" fill="white"/>
                                <path d="M142.112 31.4103L140.951 35.596H140.415L139.118 30.7151H139.617L140.679 34.8535L141.833 30.7151H142.38L143.519 34.868L144.588 30.7151H145.095L143.798 35.596H143.254L142.112 31.4103Z" fill="white"/>
                                <path d="M152.501 35.5996H149.544V30.7188H152.453V31.1592H150.058V32.8589H152.248V33.2993H150.058V35.1556H152.501V35.5996Z" fill="white"/>
                                <path d="M158.864 35.5996H157.163V30.7188H158.761C159.716 30.7188 160.279 31.1738 160.279 31.9745C160.279 32.4586 159.97 32.8699 159.47 33.0555C160.135 33.2193 160.477 33.6451 160.477 34.2311C160.477 35.101 159.83 35.5996 158.864 35.5996ZM157.644 31.1337V32.8735H158.736C159.401 32.8735 159.779 32.5459 159.779 32.0036C159.779 31.4613 159.401 31.1337 158.736 31.1337H157.644ZM157.644 33.2884V35.1847H158.838C159.558 35.1847 159.985 34.8316 159.985 34.2202C159.985 33.6342 159.558 33.2884 158.838 33.2884H157.644Z" fill="white"/>
                                <path d="M165.021 34.2602H165.481C165.514 34.8789 166.002 35.2793 166.73 35.2793C167.479 35.2793 167.946 34.8862 167.946 34.2674C167.946 33.6705 167.431 33.2884 166.627 33.2884H166.127V32.8953H166.619C167.259 32.8953 167.711 32.5095 167.711 31.949C167.711 31.4176 167.284 31.0463 166.689 31.0463C166.072 31.0463 165.653 31.4139 165.628 31.9854H165.168C165.194 31.2174 165.848 30.6605 166.704 30.6605C167.597 30.6605 168.199 31.1846 168.199 31.9308C168.199 32.433 167.883 32.8334 167.321 33.0663C168.023 33.2374 168.431 33.6705 168.431 34.2638C168.431 35.1009 167.736 35.6724 166.73 35.6724C165.734 35.6651 165.04 35.0936 165.021 34.2602Z" fill="white"/>
                            </svg>

                            {/*<img src={Logo} alt={'Logo'}/>*/}
                        </a>
                        <div className=" navbar-collapse" >
                            <ul className="navbar-nav ms-auto mb-2  mb-lg-0">
                                <li className="nav-item">
                                    <Link to="/" className={`nav-link ${location.pathname=='/'?'HomeActive':''} `} aria-current="page" href="#">HOME</Link>
                                </li>
                            </ul>
                              <button
                              onClick={()=>  navigate(`/Contact`)}
                              className={`btn btn-contact  ${location.pathname=='/Contact'?'contactactive':''} `} >
                                 
                                  CONTACT US</button>
                        </div>
                        <div className={'mobil-nav'}>
                            <ul>
                                <li className={` ${location.pathname=='/'?'':'d-none'} `}>
                                    <Link to={'/Contact'}   >

                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="40" height="40" rx="8" fill="url(#paint0_linear_67_6578)"/>
                                            <path d="M32.381 13.6475V27.9992C32.381 28.6548 32.157 29.284 31.7585 29.7481C31.3599 30.2125 30.8189 30.4742 30.2541 30.4762H9.74587C9.18113 30.4742 8.64014 30.2125 8.24153 29.7481C7.84296 29.284 7.61905 28.6548 7.61905 27.9992V13.6475L19.6262 23.2127C19.8512 23.394 20.1496 23.394 20.3747 23.2127L32.381 13.6475Z" fill="white"/>
                                            <path d="M32.368 11.8103L20 21.6577L7.63204 11.8103C7.67043 11.1894 7.90958 10.6085 8.30171 10.1842C8.69384 9.76028 9.20989 9.52414 9.74595 9.5238H30.2542C30.7902 9.52414 31.3063 9.76028 31.6985 10.1842C32.0906 10.6085 32.3297 11.1894 32.3681 11.8103H32.368Z" fill="white"/>
                                            <defs>
                                                <linearGradient id="paint0_linear_67_6578" x1="1.33333" y1="40" x2="42.7186" y2="36.9556" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#6D77B0"/>
                                                    <stop offset="1" stop-color="#9DA6CE"/>
                                                </linearGradient>
                                            </defs>
                                        </svg>

                                    </Link></li>
                                <li className={` ${location.pathname=='/Contact'?'':'d-none'} `}>
                                    <Link to={'/'}   >
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="40" height="40" rx="8" fill="url(#paint0_linear_67_8559)"/>
                                        <path d="M29.9572 21.4996C30.0273 21.5618 30.0675 21.651 30.0675 21.745V31.6727C30.0675 31.8533 29.9215 32 29.7412 32H23.0455C22.8655 32 22.7192 31.8533 22.7192 31.6727V25.3322C22.7192 24.9847 22.5818 24.6517 22.337 24.4061C22.0919 24.1605 21.76 24.0224 21.4137 24.0224H18.5865C18.2404 24.0224 17.9082 24.1605 17.6634 24.4061C17.4186 24.6517 17.2812 24.9847 17.2812 25.3322V31.6727C17.2812 31.8533 17.1349 32 16.9547 32H10.2595C10.0793 32 9.93326 31.8533 9.93326 31.6727V21.745C9.93326 21.651 9.97343 21.5618 10.0434 21.4996L19.5684 13.0589C19.8153 12.8403 20.1859 12.8403 20.4327 13.0589L29.9572 21.4996ZM32.5812 18.2244L21.7241 8.65196C21.2475 8.23181 20.6347 8 20.0003 8C19.3658 8 18.7532 8.23181 18.2766 8.65196L7.41943 18.2244C7.16332 18.4501 7.01168 18.7722 7.00062 19.1141C6.98979 19.4561 7.12022 19.7873 7.36144 20.0291V20.0293C7.58325 20.2516 7.88111 20.3811 8.19477 20.391C8.5082 20.401 8.81374 20.2908 9.04908 20.0827L19.1374 11.1686C19.3757 10.9579 19.6825 10.8417 20.0003 10.8417C20.318 10.8417 20.6247 10.9579 20.8632 11.1684L30.9515 20.0825C31.2823 20.3745 31.7435 20.4673 32.161 20.3258C32.5787 20.1844 32.8891 19.8303 32.9758 19.3968C33.0627 18.9633 32.9122 18.5162 32.5811 18.2242L32.5812 18.2244Z" fill="white"/>
                                        <defs>
                                            <linearGradient id="paint0_linear_67_8559" x1="1.33333" y1="40" x2="42.7186" y2="36.9556" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#6D77B0"/>
                                                <stop offset="1" stop-color="#9DA6CE"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>

                                    </Link>
                                </li>

                            </ul>

                        </div>
                    </div>
                </nav>
                </div>
            </header>
        </>
    );

}
export default Header;