import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../src/assets/style/style.scss';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import {Home} from './pages/index';
import {ContactUs} from './pages/index'

function App() {
  return (
    // <div className="App">

    //   <Home/>
    // </div>


    <Routes>
    {/* <Route exact path="Home" element= key="Home" /> */}
    <Route path="/" element={<Home />}></Route>
    <Route path="/Contact" element={<ContactUs />}></Route>

    {/* <Route path="*" element={<Navigate to="/authentication/sign-in" />} /> */}
  </Routes>
  );
}

export default App;
